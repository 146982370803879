<template>
    <div class="fondo-degradado-recover"></div>
    <div class="contenedor-register d-flex align-items-center justify-content-center flex-column">
        <h1 class="titulo-register">¡Mejora tu emprendimiento!</h1>
        <h2 class="subtitulo-register">Estas a pocos pasos de empezar.</h2>
        <br>
        <FormCustom
            :objectForm="[
                {
                    type: 'text',
                    label: 'Nombre',
                    name: 'nombre',                
                },
                {
                    type: 'text',
                    label: 'Apellido',
                    name: 'apellido',                
                },                    
                {
                    type: 'text',
                    label: 'Correo Electrónico',
                    name: 'email',
                },
                {
                    type: 'select',
                    label: 'Role',
                    name: 'role',
                    array: roles
                },
                {
                    type: 'select',
                    label: 'País',
                    name: 'pais',
                    array: paises,
                    width: '500px',
                    widthComplete: true
                },
                {
                    type: 'text',
                    label: 'Ciudad',
                    name: 'ciudad',
                }, 
                {
                    type: 'text',
                    label: 'Referenciado (opcional)',
                    name: 'input2',
                    description: 'Un referenciado es alguien que puede dar la capacidad de generar un descuento mediante un codigo que se le pasa al que descontado'
                },                  
                {
                    type: 'password',
                    label: 'Contraseña',
                    name: 'password',
                },                    
                {
                    type: 'password',
                    label: 'Confirmar contraseña',
                    name: 'password2',
                },  
                {
                    type: 'checkbox',
                    label: 'Al registrarte aceptas nuestros términos y condiciones',
                    name: 'caja',
                    array: ['Acepto los términos y condiciones'],
                    widthComplete: true
                },                                       
            ]"
            textSecButton="Registrarse"
            :actionSecButton="(data)=>this.registerLocal(data)"
            :limitInputsInSection="5"
            :isOneButton="false"
        >
            <template v-slot:contentBeforeButton>
                <p style="margin:0px;">
                <router-link to="/terms" class="link-register"  style="margin:0px;" >Términos y Condiciones</router-link>
                </p>
                <p class="inicio-register">
                    ¿Tienes una cuenta?<router-link to="/login" class="link-register">Iniciar sesión</router-link>
                </p>    
            </template>
        </FormCustom>
        <br>
        <div class="error-register d-flex justify-content-center align-items-center" v-if="this.stateLogin.success === 'error'">
            <div><p>{{mensaje}}</p></div>
        </div>  
        <div class="correcto-register d-flex justify-content-center align-items-center" v-if="!this.isSesionActive && this.stateLogin.success === 'ok'">
            <div><p>{{mensaje}}</p></div>
        </div> 
    </div>
</template>

<script>
    import Footer from "../../components/Footer.vue"
    import {mapState, mapActions} from "vuex"
    import {swift} from '../../helpers/swift'
    import {quitarError, quitarHecho} from '../../helpers/quitarAvisosForm'
    import FormCustom from '../../components/FormCustom.vue'

    export default {
        name: "Register",
        components: {
            Footer,
            FormCustom   
        },
        data(){
            return{
                //control de respuestas de la api
                mensaje:'',

                //carga de selects

                paises: this.paises = [
                    "Afghanistan",
                    "Albania",
                    "Algeria",
                    "American Samoa",
                    "Andorra",
                    "Angola",
                    "Anguilla",
                    "Antarctica",
                    "Antigua and Barbuda",
                    "Argentina",
                    "Armenia",
                    "Aruba",
                    "Australia",
                    "Austria",
                    "Azerbaijan",
                    "Bahamas",
                    "Bahrain",
                    "Bangladesh",
                    "Barbados",
                    "Belarus",
                    "Belgium",
                    "Belize",
                    "Benin",
                    "Bermuda",
                    "Bhutan",
                    "Bolivia",
                    "Botswana",
                    "Bouvet Island",
                    "Brazil",
                    "Brunei Darussalam",
                    "Bulgaria",
                    "Burkina Faso",
                    "Burundi",
                    "Cabo Verde",
                    "Cambodia",
                    "Cameroon",
                    "Canada",
                    "Cayman Islands",
                    "Chad",
                    "Chile",
                    "China",
                    "Christmas Island",
                    "Cocos",
                    "Colombia",
                    "Comoros",
                    "Congo",
                    "Cook Islands",
                    "Costa Rica",
                    "Croatia",
                    "Cuba",
                    "Curaçao",
                    "Cyprus",
                    "Czechia",
                    "Côte d'Ivoire",
                    "Denmark",
                    "Djibouti",
                    "Dominica",
                    "Dominican Republic",
                    "Ecuador",
                    "Egypt",
                    "El Salvador",
                    "Equatorial Guinea",
                    "Eritrea",
                    "Estonia",
                    "Eswatini",
                    "Ethiopia",
                    "Falkland Islands",
                    "Faroe Islands",
                    "Fiji",
                    "Finland",
                    "France",
                    "French Guiana",
                    "French Polynesia",
                    "Gabon",
                    "Gambia",
                    "Georgia",
                    "Germany",
                    "Ghana",
                    "Gibraltar",
                    "Greece",
                    "Greenland",
                    "Grenada",
                    "Guadeloupe",
                    "Guam",
                    "Guatemala",
                    "Guernsey",
                    "Guinea",
                    "Guinea-Bissau",
                    "Guyana",
                    "Haiti",
                    "Holy See",
                    "Honduras",
                    "Hong Kong",
                    "Hungary",
                    "Iceland",
                    "India",
                    "Indonesia",
                    "Iran",
                    "Iraq",
                    "Ireland",
                    "Isle of Man",
                    "Israel",
                    "Italy",
                    "Jamaica",
                    "Japan",
                    "Jersey",
                    "Jordan",
                    "Kazakhstan",
                    "Kenya",
                    "Kiribati",
                    "Korea",
                    "Korea",
                    "Kuwait",
                    "Kyrgyzstan",
                    "Latvia",
                    "Lebanon",
                    "Lesotho",
                    "Liberia",
                    "Libya",
                    "Liechtenstein",
                    "Lithuania",
                    "Luxembourg",
                    "Macao",
                    "Madagascar",
                    "Malawi",
                    "Malaysia",
                    "Maldives",
                    "Mali",
                    "Malta",
                    "Marshall Islands",
                    "Martinique",
                    "Mauritania",
                    "Mauritius",
                    "Mayotte",
                    "Mexico",
                    "Micronesia",
                    "Moldova",
                    "Monaco",
                    "Mongolia",
                    "Montenegro",
                    "Montserrat",
                    "Morocco",
                    "Mozambique",
                    "Myanmar",
                    "Namibia",
                    "Nauru",
                    "Nepal",
                    "Netherlands",
                    "New Caledonia",
                    "New Zealand",
                    "Nicaragua",
                    "Niger",
                    "Nigeria",
                    "Niue",
                    "Norfolk Island",
                    "Norway",
                    "Oman",
                    "Pakistan",
                    "Palau",
                    "Palestine, State of",
                    "Panama",
                    "Papua New Guinea",
                    "Paraguay",
                    "Peru",
                    "Philippines",
                    "Pitcairn",
                    "Poland",
                    "Portugal",
                    "Puerto Rico",
                    "Qatar",
                    "Romania",
                    "Russian Federation",
                    "Rwanda",
                    "Réunion",
                    "Saint Barthélemy",
                    "Saint Helena",
                    "Saint Lucia",
                    "Saint Martin",
                    "Samoa",
                    "San Marino",
                    "Saudi Arabia",
                    "Senegal",
                    "Serbia",
                    "Seychelles",
                    "Sierra Leone",
                    "Singapore",
                    "Sint Maarten",
                    "Slovakia",
                    "Slovenia",
                    "Solomon Islands",
                    "Somalia",
                    "South Africa",
                    "South Sudan",
                    "Spain",
                    "Sri Lanka",
                    "Sudan ",
                    "Suriname",
                    "Svalbard and Jan Mayen",
                    "Sweden",
                    "Switzerland",
                    "Syrian Arab Republic",
                    "Taiwan",
                    "Tajikistan",
                    "Tanzania",
                    "Thailand",
                    "Timor-Leste",
                    "Togo",
                    "Tokelau",
                    "Tonga",
                    "Trinidad and Tobago",
                    "Tunisia",
                    "Turkey",
                    "Turkmenistan",
                    "Tuvalu",
                    "Uganda",
                    "Ukraine",
                    "United Arab Emirates",
                    "United Kingdom",
                    "United States of America",
                    "Uruguay",
                    "Uzbekistan",
                    "Vanuatu",
                    "Venezuela",
                    "Viet Nam",
                    "Virgin Islands",
                    "Virgin Islands",
                    "Wallis and Futuna",
                    "Western Sahara",
                    "Yemen",
                    "Zambia",
                    "Zimbabwe",
                    "Åland Islands"
                ],
                roles: this.role = [
                    "Contador",
                    "Programador",
                    "Emprendedor",
                    "Trader",
                    "Inversor",
                    "Otros"
                ]
            }
        },

        methods: {
            ...mapActions(["login", "register", "sesionInactive", "sesionActive", "clearStateLogin", "badRegisterFront"]),
            //funcion encargada de crear un nuevo ususario

            async registerLocal(data){

                const {nombre,email,pais,password,password2,apellido,ciudad,role,caja,referenciado} = data
                let comprobadorEmail = /\w+@\w+\.+[a-z]/;

                //comprobar que los campos no esten vacios

                if(nombre === '' || email === '' || pais === '' || password === '' || password2 === '' || apellido === '' || ciudad === '' || role === '' || caja['Acepto los términos y condiciones'] === false){
                    this.badRegisterFront()
                    this.swift(`rellena todo los campos porfavor`, 1)
                    return
                }

                //comprobar que las contraseñas sean iguales

                if(password2 !== password){
                    this.badRegisterFront()                    
                    this.swift(`las contraseñas no son iguales`, 1)
                    return
                }

                //comprobar que el referenciado no sea el mismo que el correo de registro

                if(email === referenciado){
                    this.badRegisterFront()
                    this.swift(`el correo referenciado no puede ser el mismo usado para tu cuenta`, 1)
                    return
                }
                
                //comprobar que los caracteres no excedan los 30 caracteres

                this.comprobarLargo(nombre.length,`Nombre`)
                this.comprobarLargo(pais.length,`Pais`)
                this.comprobarLargo(password.length,`Contraseña`)
                this.comprobarLargo(apellido.length,`Apellido`)
                this.comprobarLargo(ciudad.length,`Ciudad`)
                this.comprobarLargo(role.length,`Role`)

                //comprobacion de correo y creacion de usuario

                if(comprobadorEmail.test(email)){
                    
                    //creacion de usuario por la api
                    await this.register({
                        contrasenia: password,
                        nombre: nombre,
                        apellido: apellido,
                        role: role,
                        email: email,
                        ciudad: ciudad,
                        pais: pais,
                        referenciado: referenciado,
                        ancho: 100,
                        alto: 100,
                        izquierda: 0,
                        arriba:0
                    })
                    
                    //control de errores
                    if(this.stateLogin.message === "Hubo un fallo en un datoSequelizeUniqueConstraintError: llave duplicada viola restricción de unicidad «Usuarios_Nombre_usuario_key»"){
                        //ya existe ese usuario
                        this.swift('Ya existe ese nombre de usuario',1)
                    }else if(this.stateLogin.message === "Hubo un fallo en un datoSequelizeUniqueConstraintError: llave duplicada viola restricción de unicidad «Usuarios_Email_key»"){
                        //el correo ya esta en uso
                        this.swift('El correo ya esta en uso',1)
                    }else if(this.stateLogin.message === "Usuario creado"){
                        await this.login({email,password})
                        localStorage.setItem('token', this.stateLogin.message)
                        this.swift('usuario creado con exito',2)
                    }else{
                        //hubo un error
                        this.swift('Hubo un error',1)
                    }
                }else{
                    this.swift(`Ingresa un correo`, 1)
                    return
                }

            },

            //funcion comprobadora de largo texto

            comprobarLargo(input,nombre){
                if(input > 30){
                    this.swift(`${nombre} no puede exceder de 30 caracteres`, 1)
                }else{
                    return
                }
            },

            //funcion encargada de quitar la caja de error
            quitarError,

            //funcion encargada de quitar la caja de correcto
            quitarHecho,

            //swift entre error y correcto
            swift,
        },
        created(){
            this.sesionInactive()
        },
        mounted(){
            scrollTo(0,0)
            this.$forceUpdate()
        },
        unmounted(){
            clearTimeout(this.timeOut)
        },        
        computed:{
        ...mapState(["token", "server","stateLogin", "isSesionActive"]),
        }
    }
</script>

<style scoped>
    .contenedor-register{
        position: relative;
        width: 100%;
    }
    .fondo-degradado-recover{
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        background: linear-gradient(290.11deg, rgba(17, 1, 51, 0.46) 0%, rgba(17, 1, 51, 0) 52.11%), linear-gradient(248.49deg, rgba(255, 255, 255, 0) 41.48%, #297F87 100.24%);
        height: 100vh;
    }
    .error-register{
        margin: 1.5em 0em 1.5em 0em;
        padding-top: 1em;
        width: 700px;
        background-color: rgb(128, 18, 22,0.75);
        color: white;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
    }
    .correcto-register{
        margin: 1.5em 0em 1.5em 0em;
        padding-top: 1em;
        width: 700px;
        background-color: rgb(11, 114, 40,0.75);
        color: white;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
    }
    .titulo-register{
        font-family: Rubik;
        font-weight: bold;
        margin-top:80px ;
        font-size: 55px;
    }
    .subtitulo-register{
        font-family: Rubik;
        margin-top:0px ;
        font-weight: bold;
        font-size: 25px;
    }
    .form-register{
        width: 75%;
    }
    .label,.label2{
        font-family: Rubik;
        margin-top: 20px;   
        margin-left: 12.5%;
        font-weight:bold ;
    }
    .label2{
        margin-left: 30%;
    }
    .input,.input2{
        border-radius:10px;
        border: #6464646b 2px solid;
        width: 75%;
        margin-left: 12.5%;
        background: white;
        padding-left: 10px;
        padding-top: 3px;
        padding-bottom: 3px;
    }
    .input2{
        width: 40%;
        margin-left:30% ;
    }
    .link-register,.link-register2{
        width: 100%;
        text-align: center;
        font-family: Rubik;
        font-weight:bold ;
        font-size: 16px;
        margin-top: 25px;
        margin-left:25px ;
        color: #297F87;
        text-decoration: none;
    }
    .link-register2{
        margin-left:5px ;
    }
    .inicio-register{
        margin: 0px;
        margin-top: 20px;
        text-align: center;
        font-family: Rubik;
        font-weight:bold ;
        font-size: 16px;
    }
    .botones-register{
        margin-top:20px;
    }
    .button-register{
        width: 50%;
        height: 40px;
        border:none;
        border-radius:10px;
        background: #297F87;
        color: white;
        text-align: center;
        font-family: Rubik;
        font-size:20px ;
        margin-left: 25%;
        margin-top: 20px;
    }
    .checkbox-register{
        width: 25px;
        height: 25px;
        border-radius:4px;
        border: #6464646b 2px solid;
        margin-right: 15px;
        margin-top:-8px;
        background: white;
    }
    .caja-register{
        width: 17px;
        height: 17px;
        background: #297F87;
        border-radius:2px;
        opacity: 0;
    }
</style>